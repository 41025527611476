import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Link, Box } from "@material-ui/core";
import { graphql } from "gatsby";
import RealLink from "../components/Link";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    overflowX: "hidden",
    background: "#09333F",
    backgroundSize: "100% 100%",
    animation: "$gradient 8s ease infinite",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
    },
  },
  root: {
    margin: "2rem",
    [theme.breakpoints.down("sm")]: {
      margin: "0rem",
      padding: 0,
    },
  },
  ueberschrift: {
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  lastElement: {
    borderBottom: "1px solid #000",
    marginBottom: "2rem",
  },
  ueberschriften: {
    fontWeight: "bold",
  },
  ueberschriften2: {
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  ueberschriften3: {
    marginBottom: "4rem",
  },
  mailLogo: {
    width: "20px",
    height: "20px",
    color: "#000",
  },
  footbox: {
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  link: {
    color: "#abcdee",
    cursor: "pointer",
    marginLeft: "0.5rem",
    textDecoration: "none",
  },
  absatz: {
    marginBottom: "1rem",
  },
  boxMail: {
    paddingTop: "0.1rem",
  },
}));

const getSections = (sections) => sections?.allMediaCreditsJson?.edges || [];

const getSection = (section) => ({
  ...section?.node,
  elements: section?.node?.elements?.map((element) => GetElement(element)),
});

const GetElement = (element) => {
  const classes = useStyles();
  switch (element.type) {
    case "text":
      return ` ${element.text} `;
    case "link":
      return (
        <RealLink external href={element.href} className={classes.link}>
          {element.text}
        </RealLink>
      );
    default:
      return null;
  }
};

export default function Impressum({ data }) {
  const classes = useStyles();

  const sections = getSections(data).map((section) => getSection(section));
  return (
    <div className={classes.mainRoot}>
      <Navbar />
      <Grid container className={classes.root}>
        <Grid item xs={2} />
        <Grid item container xs={8}>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="h3" component="h1">
              IMPRESSUM
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h2"
              className={classes.ueberschriften}
            >
              Betreiber dieser Seite:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              M3E GmbH - Fullserviceagentur und Beratung für Elektromobilität
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Torstraße 23
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              10119 Berlin
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Registergericht: Amtsgericht Charlottenburg
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschriften2}>
            <Typography
              variant="h5"
              component="h2"
              className={classes.ueberschriften}
            >
              HRB 70275
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              Tel.: 030/403672121
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              Email:
              <Link className={classes.link}>info[at]m3e-gmbh.com</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              Geschäftsführer: Dr. Christian Milan
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              Umsatzsteuer-Idientifikationsnummer gemäß § 27 a
              Umsatzsteuergesetz:
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              USt-IdNr. DE200269605
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.lastElement}>
            <Typography variant="body1" component="p"></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h2"
              className={classes.ueberschriften}
            >
              Verantwortlicher nach § 55 Abs.2 RStV:
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              Dr. Christian Milan
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              M3E GmbH - Fullserviceagentur und Beratung für Elektromobilität
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Torstraße 23
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ueberschrift}>
            <Typography variant="body1" component="p">
              10119 Berlin
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.lastElement}>
            <Typography variant="body1" component="p"></Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mb={3}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.ueberschriften}
              >
                Bild-/Videonachweise für Website:
              </Typography>
            </Box>
          </Grid>
          {sections.map((section, idx) => (
            <Grid key={idx} item xs={12} className={classes.absatz}>
              <Typography>{section.elements}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Footer />
    </div>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMediaCreditsJson {
      edges {
        node {
          elements {
            href
            text
            type
          }
        }
      }
    }
  }
`;
